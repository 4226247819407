<template>
  <div class="wrap">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <div style="display:flex;justify-content: space-between; margin-bottom: 12px">
          <div>地址来源：{{attribute/1===0?"公共": "个人"}}</div>
          <div>
            <el-button @click="comeBack">返回</el-button>
          </div>
        </div>
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <el-button
              style="background: #F18300;color: #fff;border:1px solid #F18300"
              @click="onAdd"
            >新增</el-button>
          </div>
        </ayl-table>
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      top="none"
      width="32%"
      center
    >
      <component
        v-bind:is="targetComponent"
        @visible="isVisible"
        :dialogParams="dialogParams"
        :key="dialogKey"
      ></component>
    </el-dialog>
  </div>
</template>

<script>
import PublicAdd from "./add";
import PublicEdit from "./edit";
export default {
  name: "TransportAddressPublic",
  components: {
    // PublicAdd: () => import("./add"),
    // PublicEdit: () => import("./edit"),
    PublicAdd,
    PublicEdit,
  },
  data() {
    return {
      nav: [
        { name: "系统管理" },
        { name: "托运地址" },
        { name: "地址明细", path: "/system-manage/transport-address/public" },
      ],
      id: this.$route.query.id,
      attribute: this.$route.query.attribute,

      // 弹窗参数
      targetComponent: null,
      dialogVisible: false,
      dialogTitle: null,
      dialogParams: null,
      dialogKey: 0,

      table: {
        api: this.$api.consignOrder_getAddressDetailList,
        query: {
          queryContent: null,
          id: this.$route.query.id,
          attribute: this.$route.query.attribute,
        },
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "地区/详细地址",
          },
        ],
        columns: [
          {
            title: "序号",
            width: "60px",
            align: "center",
            $index: "index",
          },
          {
            title: "地区",
            showTooltip: true,
            key: "areaDes",
          },
          {
            title: "详细地址",
            key: "addressDetail",
          },
          {
            title: "标签",
            key: "addresstags",
            render: (h,ctx)=>{
              return h('div',ctx.row.addresstags?"默认地址":"无")
            }
          },
          {
            title: "操作",
            width: "120px",
            align: "center",
            render: (h, ctx) => {
              return h("div", [
                h(
                  "el-button",
                  {
                    props: {
                      type: "text",
                    },
                    style: {
                      color: "#0575E6",
                    },
                    on: {
                      click: () => this.onEdit(ctx),
                    },
                  },
                  "编辑"
                ),
                h(
                  "el-button",
                  {
                    props: {
                      type: "text",
                    },
                    style: {
                      color: "#f44",
                    },
                    on: {
                      click: () => this.onDelete(ctx),
                    },
                  },
                  "删除"
                ),
              ]);
            },
          },
        ],
      },
    };
  },
  created() {
    console.log(this.id, this.attribute);
  },
  methods: {
    
    onDelete(val) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api
          .consignOrder_deleteAddressInfo({
            addressId: val.row.id,
          })
          .then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$search(this.table);
          });
      });
    },
    comeBack() {
      this.$router.go(-1);
    },
    onAdd() {
      this.dialogVisible = true;
      this.targetComponent = "PublicAdd";
      this.dialogTitle = "新增地址";
      this.dialogParams = null;
      this.dialogKey++;
    },
    onEdit(val) {
      this.dialogVisible = true;
      this.targetComponent = "PublicEdit";
      this.dialogTitle = "编辑地址";
      this.dialogParams = val.row;
      this.dialogKey++;
    },
    isVisible(val) {
      this.dialogVisible = false;
      if (val) {
        this.$search(this.table);
      }
    },
  },
};
</script>

<style lang="sass" scoped>

.wrap 
  width: 100%
/deep/ .el-dialog__wrapper 
  display: flex;
  align-items: center;

</style>